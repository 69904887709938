<template>
  <div class="ProjectIntroduction">
    <heade></heade>
    <div class="cen_wrap">
      <div class="cen">
        <ul class="con_box">
          <li v-for="(item, index) in keyProjects" :key="index">
            <router-link :to="{
              path: '/project/list',
              query: { id: item.id },
            }">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="link_box">
        <div class="link">
          <router-link class="add" to="/project">课题研究介绍</router-link>
        </div>
        <div class="link" v-for="(item, index) in goodsCategories" :key="index">
          <router-link :to="{
            path: '/goods/goods',
            query: { id: item.id },
          }">{{ item.name }}</router-link>
        </div>
        <div class="link">
          <router-link to="/article/primaryEquip">人教版全国中小学传统文化教学一体机</router-link>
        </div>
        <!-- <div class="link">
          <router-link to="AuxiliaryReading2"
            >中小学传统文化数字资源研究传播中心配套校园终端服务器</router-link
          >
        </div> -->
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getKeyProjects, getGoodsCategories } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data: function () {
    return {
      keyProjects: [],
      goodsCategories: [],
      backgroud: "",
    };
  },
  mounted: function () {
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    let that = this;
    getKeyProjects().then(function (res) {
      that.$set(that, "keyProjects", res.data[0].children);
    });
    getGoodsCategories({ pid: 7 }).then(function (res) {
      that.$set(that, "goodsCategories", res.data.list);
    });
  },
  created: function () {
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
.ProjectIntroduction .cen_wrap {
  width: 100%;
  height: 1536px;
  background: url(../../assets/images/ketijieshaoBg.jpg);
  background-size: cover;
  background-position: center 0;
}

.ProjectIntroduction .cen {
  width: 1484px;
  height: 965px;
  position: relative;
  top: 122px;
  left: 212px;
}

.cen>.con_box {
  width: 585px;
  height: 298px;
  position: relative;
  top: 382px;
  left: 758px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.con_box>li {
  height: 48px;
  font-size: 18px;
  line-height: 48px;
}

.con_box>li>a {
  color: rgb(104, 4, 4);
}

.ProjectIntroduction .link_box {
  width: 800px;
  height: 250px;
  position: relative;
  top: 120px;
  left: 460px;
  display: flex;
  align-items: center;
}

.ProjectIntroduction .link {
  height: 220px;
  writing-mode: vertical-rl;
  padding-right: 60px;
}

.ProjectIntroduction .link>a {
  color: #006649;
  font-size: 18px;
  font-family: kaiti;
  font-weight: 600;
  line-height: 26px;
}

.link>a:hover {
  color: #900c0c;
}

.ProjectIntroduction .link>.add {
  color: #900c0c;
}
</style>